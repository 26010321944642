import React from 'react'

import { Hero, Wrapper, SEO } from '../components'

import styles from './404.module.scss'

class NotFoundPage extends React.Component {
  render() {
    return (
      <>
        <SEO title="Page Not Found" />
        <Hero
          heroImg='/images/404.jpeg'
          title='404'
        />
        <Wrapper>
          <h1 className={styles.title}>404 Page Not Found</h1>
          <div className={styles.icon}>
            <img src='/images/ufo-and-cow.svg' alt='ufo and cow' />
          </div>
          <p className={styles.text}>
            Looks like you've followed a broken link or entered a URL that
            doesn't exist on this site.
          </p>
        </Wrapper>
      </>
    )
  }
}

export default NotFoundPage
